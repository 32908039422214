
.previewImage {
    /* max-width: 100%; */
    width: 100%;
    max-height: 15em;
    object-fit: cover;

    /* border-radius: 10px; */

    margin: auto;
}

.placeholderImage {
    width: 30em;
    height: 10em;
}

.carouselContainer {
    width: 100%;
    max-height: 15em;
}
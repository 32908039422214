.App {
  height: 100vh;
  display: flex;
  z-index: 1;
}

.Overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  padding: 0px;
  margin: 5px;

  max-width: 100%;
  height: 100%;

  overflow: hidden;
}

.Sidebar {
  /*
  width: 50px;
  height: 100%;
  */

  width: 0px;
  visibility: hidden;
}
* {
    font-family: Roboto, Arial,sans-serif;
}

.placeInfo {
    margin: 5px;

    max-width: 30em;
    max-height: 100%;

    border-radius: 10px;
    border-color: silver;
    border-style: ridge;
    border-width: thin;

    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    background-color: #FFFFFF;
	position: relative;
	box-shadow: 0px 0px 5px 0px #6C77B2;
}

.placeName {
    font-size: x-large;
    margin: 10px;
    text-align: left;
}

.description {
    text-size-adjust: none;
    margin: 10px;
}

.closeButton {
    text-align: center;
    margin: 5px;
}

.section-divider-bottom-line {
    border-bottom: 1px solid #e6e6e6;
}

.section-divider-vertical-line {
    border-right: 1px solid #e6e6e6;
    padding: 5px;
}

.actionBar {
    width: 100%;
    justify-content: center;
}

.links {
    text-align: center;
    margin: 5px;
}

.actionBar {
    display: inline-flex;
    vertical-align: middle;
    padding: 5px;
}

.actionItem {
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    text-align: center;
}